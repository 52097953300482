@import 'Styles/includes';

/**
*
* CardOffice
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardOffice {
    $root: &;

    flex: 1;

    &--Center {
        display: flex;
        height: 100%;
    }

    &__ContactInfoContainer {
        width: 100%;
        text-align: left;

        #{$root}--Shadow & {
            box-shadow: 0 0 4px 0 rgba(black, 0.15);
            border-radius: 4px;
            padding: 24px 0;

            @include media(md) {
                padding: 36px 0;
            }
        }

        #{$root}--Center & {
            text-align: center;
        }
    }


    &__Image {
        width: 100px;
        height: 100px;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $gray-10;
        border-radius: 50%;

        #{$root}--Center & {
            margin: 0 auto 24px auto;
        }

        &--Fallback {
            //border-radius: 0 !important;
            //background-color: transparent;
            background-size: 32px;
            background-image: url('#{$publicpath}img/icon-office.svg');
        }
    }

    &__ContactInfoTitle {
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__ContactInfoText {
        font-size: 1.4rem;
        color: $text;
        margin-bottom: 10px;
    }

    &__ContactInfoTitleLink {
        display: block;
        text-decoration: none;
        color: $red;
        width: auto;
        word-break: break-all;
        line-height: 1.25;
        display: inline-flex;
        align-items: baseline;
        position: relative;
        margin-bottom: 4px;

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }

    &__ContactInfo {
        border-top: 1px solid $gray-10;
        margin-top: 15px;
        padding-top: 20px;

        #{$root}--Center & {
            border-top: none;
        }
    }

    &__ContactLink {
        display: block;
        text-decoration: none;
        color: $red;
        width: auto;
        font-size: 1.4rem;
        white-space: nowrap;
        word-break: break-all;
        line-height: 1.25;
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-bottom: 4px;
        align-items: center;
        justify-content: center;
        margin-left: -14px;

        &:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        &--Email {
            text-transform: lowercase;
            &:before {
                background-image: url('#{$publicpath}img/icon-email.svg');
            }
        }

        &--Phone {
            &:before {
                background-image: url('#{$publicpath}img/icon-old-phone.svg');
            }
        }

        &--Location {
            &:before {
                background-image: url('#{$publicpath}img/icon-location-black.svg');
                height: 8px;
                padding-bottom: 8px;
            }
        }

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }
}
